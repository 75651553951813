import { Field, Formik, FormikHelpers, Form } from 'formik';
import { useState } from 'react';
import { SignupInitialValues, SignupSchema, TSignupSchema } from '../../constants/yup';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import { toast } from 'react-toastify';
import { TSignupPayload } from '../../interfaces/api';
import { signup } from '../../services/api';
import { handleAxiosError } from '../../utils/api';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordStrengthBar from 'react-password-strength-bar';

export default function SignupForm() {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSignUp = async (
    values: TSignupSchema,
    { setSubmitting }: FormikHelpers<TSignupSchema>
  ) => {
    try {
      setSubmitting(true);
      toast.info('Signing up...');
      const payload: TSignupPayload = {
        companyName: values.companyName,
        email: values.email.trim().toLowerCase(),
        name: values.name,
        password: values.password.trim(),
        role: 'admin'
      };
      const response = await signup(payload);
      if (response.status === 200) {
        toast.success('Signed up successfully');
        navigate('/auth/email-confirmation', {
          state: { email: values.email.trim().toLowerCase() }
        });
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('Error signing up', errMsg);
      toast.error(`Error signing up: ${errMsg}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={SignupInitialValues}
      validationSchema={SignupSchema}
      onSubmit={handleSignUp}>
      {({ errors, touched, isSubmitting, values }) => (
        <Form>
          <FormControl sx={{ my: 1 }}>
            <FormLabel>Name</FormLabel>
            <Field as={Input} name="name" placeholder="Name" error={touched.name && errors.name} />
            {touched.name && errors.name && <FormHelperText>{errors.name}</FormHelperText>}
          </FormControl>

          <FormControl sx={{ my: 1 }}>
            <FormLabel>Email</FormLabel>
            <Field
              as={Input}
              name="email"
              placeholder="Email"
              error={touched.email && errors.email}
            />
            {touched.email && errors.email && <FormHelperText>{errors.email}</FormHelperText>}
          </FormControl>

          <FormControl sx={{ my: 1 }}>
            <FormLabel>Password</FormLabel>
            <Field
              as={Input}
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter your password"
              error={touched.password && errors.password}
              endDecorator={
                showPassword ? (
                  <VisibilityOff
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <Visibility sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(true)} />
                )
              }
            />
            {touched.password && errors.password && (
              <FormHelperText>{errors.password}</FormHelperText>
            )}
          </FormControl>

          <FormControl sx={{ my: 1 }}>
            <FormLabel>Confirm Password</FormLabel>
            <Field
              as={Input}
              name="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              placeholder="Confirm your password"
              error={touched.confirmPassword && errors.confirmPassword}
              endDecorator={
                showPassword ? (
                  <VisibilityOff
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <Visibility sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(true)} />
                )
              }
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <FormHelperText>{errors.confirmPassword}</FormHelperText>
            )}
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <PasswordStrengthBar password={values.password} />
          </Box>

          <FormControl sx={{ my: 1 }}>
            <FormLabel>Company</FormLabel>
            <Field
              as={Input}
              name="companyName"
              placeholder="Company Name"
              error={touched.companyName && errors.companyName}
            />
            {touched.companyName && errors.companyName && (
              <FormHelperText>{errors.companyName}</FormHelperText>
            )}
          </FormControl>

          <Button type="submit" fullWidth sx={{ my: 2 }} disabled={isSubmitting}>
            Sign Up
          </Button>
        </Form>
      )}
    </Formik>
  );
}
