import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Dropdown,
  MenuButton
} from '@mui/joy';
import { Menu as MenuIcon, AccountCircle } from '@mui/icons-material';

interface NavbarProps {
  toggleDrawer: () => void;
}

const Navbar: FC<NavbarProps> = ({ toggleDrawer }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        backgroundColor: 'background.level1'
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton variant="outlined" onClick={toggleDrawer} sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography level="h4" component="h1" color="primary">
            Engage Switch
          </Typography>
        </Link>
      </Box>

      <Box>
        <Dropdown>
          <MenuButton
            sx={{ marginRight: '1rem' }}
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: 'plain', color: 'neutral' } }}>
            <Avatar variant="solid" alt="Profile Icon" />
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircle sx={{ width: 24, height: 24, mr: 1 }} />
                <Typography>My Profile</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
};

export default Navbar;
