import Shake from '@shakebugs/browser';
import { IUser } from '../interfaces/common';

export const setShakeUser = (user: IUser) => {
  Shake.registerUser(user.email);
};

export const unsetShakeUser = () => {
  Shake.unregisterUser();
};

export const setShakeMetadata = (user: IUser) => {
  Shake.updateUserMetadata({
    name: user.name,
    email: user.email
  });
};
