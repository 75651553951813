import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import {
  CssVarsProvider,
  Sheet,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  FormHelperText,
  Divider
} from '@mui/joy';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ResetPasswordInitialValues,
  ResetPasswordSchema,
  TResetPasswordSchema
} from '../../constants/yup';
import { resetPassword } from '../../services/api';
import { handleAxiosError } from '../../utils/api';
import { toast } from 'react-toastify';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import PasswordStrengthBar from 'react-password-strength-bar';
import useRedirect from '../../hooks/useRedirect';

function ResetPasswordPage() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useRedirect();

  const email = useLocation().state?.email || '';

  useEffect(() => {
    if (!email) {
      navigate('/auth/forgot-password');
    }
  }, [email, navigate]);

  const handleResetPassword = async (
    values: TResetPasswordSchema,
    { setSubmitting }: FormikHelpers<TResetPasswordSchema>
  ) => {
    try {
      setSubmitting(true);
      const response = await resetPassword({
        email: values.email.trim().toLowerCase(),
        confirmationCode: values.confirmationCode,
        newPassword: values.newPassword.trim()
      });
      if (response.status === 200) {
        toast.success('Password reset successfully');
        navigate('/auth');
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      toast.error(`Error resetting password: ${errMsg}`);
      console.warn('error resetting password', errMsg);
      setApiError('Failed to reset password. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CssVarsProvider>
      <Box sx={{ display: 'flex', height: '100vh', flexDirection: isMobile ? 'column' : 'row' }}>
        <Sheet
          sx={{
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: isMobile ? 2 : 4,
            background: 'white'
          }}>
          <Typography level="h2" component="h1" sx={{ mb: 4 }}>
            EngageSwitch
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Divider>Reset your password</Divider>
            <Formik
              initialValues={{ ...ResetPasswordInitialValues, email }}
              validationSchema={ResetPasswordSchema}
              onSubmit={handleResetPassword}>
              {({ errors, touched, isSubmitting, values }) => (
                <Form>
                  <FormControl sx={{ my: 2 }}>
                    <FormLabel>Email</FormLabel>
                    <Field
                      as={Input}
                      name="email"
                      placeholder="Email"
                      error={touched.email && errors.email}
                      disabled
                    />
                    {touched.email && errors.email && (
                      <FormHelperText>{errors.email}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl sx={{ my: 2 }}>
                    <FormLabel>Verification Code</FormLabel>
                    <Field
                      as={Input}
                      name="confirmationCode"
                      placeholder="Enter confirmation code"
                      error={touched.confirmationCode && errors.confirmationCode}
                    />
                    {touched.confirmationCode && errors.confirmationCode && (
                      <FormHelperText>{errors.confirmationCode}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl sx={{ my: 2 }}>
                    <FormLabel>New Password</FormLabel>
                    <Field
                      as={Input}
                      name="newPassword"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter new password"
                      error={touched.newPassword && errors.newPassword}
                      endDecorator={
                        showPassword ? (
                          <VisibilityOffIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <VisibilityOnIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setShowPassword(true)}
                          />
                        )
                      }
                    />
                    {touched.newPassword && errors.newPassword && (
                      <FormHelperText>{errors.newPassword}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl sx={{ my: 2 }}>
                    <FormLabel>Confirm New Password</FormLabel>
                    <Field
                      as={Input}
                      name="confirmNewPassword"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Confirm new password"
                      error={touched.confirmNewPassword && errors.confirmNewPassword}
                      endDecorator={
                        showPassword ? (
                          <VisibilityOffIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <VisibilityOnIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setShowPassword(true)}
                          />
                        )
                      }
                    />
                    {touched.confirmNewPassword && errors.confirmNewPassword && (
                      <FormHelperText>{errors.confirmNewPassword}</FormHelperText>
                    )}
                  </FormControl>

                  <Box sx={{ mt: 2 }}>
                    <PasswordStrengthBar password={values.newPassword} />
                  </Box>

                  {apiError && <Typography sx={{ color: 'red', mb: 2 }}>{apiError}</Typography>}

                  <Button type="submit" fullWidth sx={{ mt: 2 }} disabled={isSubmitting}>
                    Reset Password
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Sheet>

        {/* Right side content remains unchanged */}
        <Sheet
          sx={{
            width: isMobile ? '100%' : '50%',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            p: isMobile ? 2 : 4,
            mt: isMobile ? 2 : 0
          }}>
          <Typography level="h2" sx={{ mb: 2 }}>
            Revolutionize Your Marketing Strategy
          </Typography>
          <Typography level="body-md" sx={{ mb: 4, textAlign: 'center' }}>
            Harness the power of automation. EngageSwitch is your key to consistent, high-impact
            email marketing.
          </Typography>
          <Button variant="solid" color="primary" size="lg">
            Try EngageSwitch.com
          </Button>
        </Sheet>
      </Box>
    </CssVarsProvider>
  );
}

export default ResetPasswordPage;
