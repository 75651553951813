import {
  Box,
  Button,
  Card,
  CssVarsProvider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Sheet,
  Typography
} from '@mui/joy';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SendGridLogo from '../assets/sendgrid.svg';
import {
  SenderEmailInitialValues,
  SenderEmailSchema,
  TSenderEmailSchema,
  TValidateSendGridSchema,
  ValidateSendGridInitalValues,
  ValidateSendGridSchema
} from '../constants/yup';
import { TAddSenderEmailsPayload, TValidateSendGridApiKeyPayload } from '../interfaces/api';
import {
  addSenderEmails,
  getSenderEmails,
  getUserDetails,
  validateSendGripApiKey
} from '../services/api';
import { useAppDispatch, useAppSelector } from '../store';
import { updateUser } from '../store/app';
import { handleAxiosError } from '../utils/api';

const SendgridIntegrationPage: React.FC = () => {
  const [senderEmail1, setSenderEmail1] = useState<string>('');
  const [senderEmail2, setSenderEmail2] = useState<string>('');

  const user = useAppSelector((state) => state.app.user);
  const placeholder = user?.company?.sendgrid_api_key || '';

  useEffect(() => {
    const req = async () => {
      try {
        const response = await getSenderEmails();
        setSenderEmail1(response.data.email1);
        setSenderEmail2(response.data.email2);
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(`Error fetching sender emails: ${errMsg}`);
        console.warn('Error fetching sender emails', errMsg);
      }
    };
    req();
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleAddSenderEmails = async (
    values: TSenderEmailSchema,
    { setSubmitting, setErrors }: FormikHelpers<TSenderEmailSchema>
  ) => {
    try {
      setSubmitting(true);
      if (user) {
        const payload: TAddSenderEmailsPayload = {
          email1: values.senderEmail1.trim().toLowerCase(),
          email2: values.senderEmail2.trim().toLowerCase()
        };
        const response = await addSenderEmails(payload);
        if (response.status === 200) {
          toast.success('Sender emails added successfully');
        }
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('Error adding sender emails', errMsg);
      toast.error(`Error adding sender emails: ${errMsg}`);
      setErrors({ senderEmail1: errMsg, senderEmail2: errMsg });
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateApiKey = async (
    values: TValidateSendGridSchema,
    { setSubmitting, setErrors }: FormikHelpers<TValidateSendGridSchema>
  ) => {
    try {
      setSubmitting(true);
      if (user) {
        const payload: TValidateSendGridApiKeyPayload = {
          apiKey: values.apiKey,
          companyId: user.id
        };

        const response = await validateSendGripApiKey(payload);

        if (response.status === 200) {
          toast.success('Validation Successful');

          const userResponse = await getUserDetails();
          const updatedUserData = userResponse.data;

          if (updatedUserData) {
            dispatch(updateUser(updatedUserData));
            navigate('/dashboard');
          } else {
            throw new Error('Updated user data not found in response');
          }
        } else {
          throw new Error('Failed to validate API key');
        }
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('Error validating API key', errMsg);
      toast.error(`Error validating API key: ${errMsg}`);
      setErrors({ apiKey: errMsg });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteApiKey = () => {};

  return (
    <CssVarsProvider>
      <Sheet
        sx={{
          margin: 'auto',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Card
            variant="soft"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img src={SendGridLogo} alt="send_grid_logo" />
          </Card>
          <Typography level="h4">integration</Typography>
        </Box>

        <Typography level="title-md" color="neutral">
          Connect with Sendgrid to more safely send high volumes of emails.
        </Typography>

        <Card variant="outlined" sx={{ mt: 2 }}>
          <Typography level="title-md">Sender emails</Typography>
          <Formik
            initialValues={SenderEmailInitialValues}
            validationSchema={SenderEmailSchema}
            onSubmit={handleAddSenderEmails}>
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Typography level="body-sm" sx={{ mb: 1 }}>
                  Sender email 1
                </Typography>
                <FormControl sx={{ mb: 2 }}>
                  <Field
                    as={Input}
                    name="senderEmail1"
                    placeholder={senderEmail1}
                    error={touched.senderEmail1 && errors.senderEmail1}></Field>
                  {touched.senderEmail1 && errors.senderEmail1 && (
                    <FormHelperText>{errors.senderEmail1}</FormHelperText>
                  )}
                </FormControl>

                <Typography level="body-sm" sx={{ mb: 1 }}>
                  Sender email 2
                </Typography>
                <FormControl sx={{ mb: 2 }}>
                  <Field
                    as={Input}
                    name="senderEmail2"
                    placeholder={senderEmail2}
                    error={touched.senderEmail2 && errors.senderEmail2}></Field>
                  {touched.senderEmail2 && errors.senderEmail2 && (
                    <FormHelperText>{errors.senderEmail2}</FormHelperText>
                  )}
                </FormControl>

                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid>
                    <Button type="submit" loading={isSubmitting} sx={{ alignSelf: 'flex-start' }}>
                      Add sender emails
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>

        <Card variant="outlined" sx={{ mt: 2 }}>
          <Typography level="title-md">Sendgrid account</Typography>
          <Formik
            initialValues={ValidateSendGridInitalValues}
            validationSchema={ValidateSendGridSchema}
            onSubmit={handleUpdateApiKey}>
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Typography level="body-sm" sx={{ mb: 1 }}>
                  Sendgrid API key
                </Typography>
                <FormControl sx={{ mb: 2 }}>
                  <Field
                    as={Input}
                    name="apiKey"
                    placeholder={placeholder}
                    error={touched.apiKey && errors.apiKey}></Field>
                  {touched.apiKey && errors.apiKey && (
                    <FormHelperText>{errors.apiKey}</FormHelperText>
                  )}
                </FormControl>

                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid>
                    <Button type="submit" loading={isSubmitting} sx={{ alignSelf: 'flex-start' }}>
                      Update API key
                    </Button>
                  </Grid>
                  {placeholder && (
                    <Grid>
                      <Button
                        type="button"
                        onClick={handleDeleteApiKey}
                        color="danger"
                        disabled={isSubmitting}
                        sx={{ alignSelf: 'flex-start' }}>
                        Delete API key
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      </Sheet>
    </CssVarsProvider>
  );
};

export default SendgridIntegrationPage;
