import React, { useState, useRef, useMemo } from 'react';
import {
  CssVarsProvider,
  Sheet,
  Typography,
  Button,
  Box,
  Select,
  Option,
  FormControl,
  Table,
  Tooltip,
  Grid,
  Snackbar,
  IconButton
} from '@mui/joy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import { ArrowBack, Warning } from '@mui/icons-material';
import { useAppSelector } from '../store';
import { uploadContacts, uploadContactsToSequence } from '../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa, { ParseResult } from 'papaparse';
import { handleAxiosError } from '../utils/api';

const ROW_LIMIT = 1000;

const appHeaders = [
  'First Name',
  'Last Name',
  'Email',
  'Industry',
  'Job Title',
  'Company Name',
  'Company Size',
  'Phone',
  'LinkedIn'
];

const requiredHeaders = ['Email', 'First Name'];

const headerMapping: Record<string, string> = {
  'First Name': 'firstName',
  'Last Name': 'lastName',
  Email: 'email',
  Industry: 'industry',
  'Job Title': 'jobTitle',
  'Company Name': 'companyName',
  'Company Size': 'companySize',
  Phone: 'phone',
  LinkedIn: 'linkedin'
};

interface ContactRow {
  [key: string]: string;
}

const UploadContactsPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.app);
  const location = useLocation();

  const sequenceId: number | undefined = location?.state?.sequenceId;
  console.log(sequenceId);

  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [fileRows, setFileRows] = useState<ContactRow[]>([]);
  const [fileName, setFileName] = useState<string>('');

  const initialColumnMapping = useMemo(
    () =>
      appHeaders.reduce((acc, header) => {
        acc[header] = 'N/A';
        return acc;
      }, {} as Record<string, string>),
    []
  );

  const [columnMapping, setColumnMapping] = useState<Record<string, string>>(initialColumnMapping);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [uploadingContacts, setUploadingContacts] = useState(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.name.endsWith('.csv')) {
      setFileName(file.name);

      Papa.parse<ContactRow>(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results: ParseResult<ContactRow>) => {
          if (results.errors.length > 0) {
            console.error('Error parsing CSV:', results.errors);
            toast.error('Error parsing CSV file.');
            setFileHeaders([]);
            setFileRows([]);
            setFileName('');
            return;
          }

          const parsedData = results.data;
          const headers = results.meta.fields || [];

          if (parsedData.length === 0) {
            toast.error('The CSV file is empty or has invalid format.');
            setFileHeaders([]);
            setFileRows([]);
            setFileName('');
            return;
          }

          if (parsedData.length > ROW_LIMIT) {
            toast.error(`Only ${ROW_LIMIT} rows are allowed to be uploaded.`);
            setFileHeaders([]);
            setFileRows([]);
            setFileName('');
            return;
          }

          setFileHeaders(headers);
          setFileRows(parsedData);
        }
      });
    } else {
      toast.error('Please upload a valid .csv file');
    }
  };

  const handleMappingChange = (appHeader: string, fileHeader: string) => {
    setColumnMapping((prev) => {
      const isAlreadyMapped = Object.entries(prev).some(
        ([key, value]) => key !== appHeader && value === fileHeader && value !== 'N/A'
      );

      if (isAlreadyMapped && fileHeader !== 'N/A') {
        toast.error(`Column "${fileHeader}" is already mapped.`);
        return prev;
      }

      return { ...prev, [appHeader]: fileHeader };
    });
  };

  const selectedAppHeaders = useMemo(
    () =>
      appHeaders.filter(
        (appHeader) => columnMapping[appHeader] && columnMapping[appHeader] !== 'N/A'
      ),
    [columnMapping]
  );

  const hasAnyColumnsMapped = useMemo(
    () => Object.values(columnMapping).some((mappedColumn) => mappedColumn !== 'N/A'),
    [columnMapping]
  );

  const hasRequiredColumnsMapped = useMemo(
    () =>
      requiredHeaders.every((header) => columnMapping[header] && columnMapping[header] !== 'N/A'),
    [columnMapping]
  );

  const getPreviewData = () => {
    return fileRows.slice(0, 5).map((row) => {
      return selectedAppHeaders.map((appHeader) => {
        const mappedFileHeader = columnMapping[appHeader];
        const cellValue = row[mappedFileHeader] || 'N/A';
        return cellValue;
      });
    });
  };

  const handleUploadContacts = async () => {
    setUploadingContacts(true);
    if (!hasRequiredColumnsMapped) {
      toast.error('Please map "First Name" and "Email" columns');
      setUploadingContacts(false);
      return;
    }

    const cleanedContacts = fileRows.map((row): Record<string, string | number> => {
      const mappedContact: Record<string, string | number> = {};

      appHeaders.forEach((appHeader) => {
        const mappedFileHeader = columnMapping[appHeader];

        if (mappedFileHeader && mappedFileHeader !== 'N/A') {
          const propertyName = headerMapping[appHeader];
          let cellValue = row[mappedFileHeader];

          if (cellValue !== undefined && cellValue !== null) {
            cellValue = cellValue.toString().trim();
            if (cellValue === '""') {
              cellValue = '';
            }
          } else {
            cellValue = '';
          }

          mappedContact[propertyName] = cellValue.toLowerCase();
        }
      });

      mappedContact['userId'] = user?.id || 0;
      mappedContact['companyId'] = user?.id || 0;
      mappedContact['fileName'] = fileName;

      return mappedContact;
    });

    const validContacts = cleanedContacts.filter((contact) => {
      const firstName = contact['firstName'] as string;
      const email = contact['email'] as string;

      return firstName && firstName.trim() !== '' && email && email.trim() !== '';
    });

    if (validContacts.length === 0) {
      toast.error('No valid contacts to upload. All rows must contain a first name and email.');
      setUploadingContacts(false);
      return;
    }

    const skippedContacts = cleanedContacts.length - validContacts.length;
    if (skippedContacts > 0) {
      toast.info(`${skippedContacts} contact(s) were skipped due to missing required fields.`);
    }

    if (!sequenceId) {
      try {
        const response = await uploadContacts(validContacts);
        const { successfullyImported, failedImports } = response.data || {};
        setSnackbarMessage(
          `${successfullyImported} contact(s) uploaded successfully, ${failedImports} failed`
        );
        setSnackbarOpen(true);
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(`Error uploading contacts: ${errMsg}`);
        console.error('Upload error:', errMsg);
      } finally {
        setUploadingContacts(false);
      }
    } else {
      try {
        const payload = {
          sequenceId: sequenceId,
          contacts: {
            contacts: validContacts
          }
        };
        await uploadContactsToSequence(payload);
        const numberOfContacts = validContacts.length;
        setSnackbarMessage(`${numberOfContacts} contact(s) uploaded successfully`);
        setSnackbarOpen(true);
        window.history.back();
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(`Error uploading contacts: ${errMsg}`);
        console.error('Upload error:', errMsg);
      } finally {
        setUploadingContacts(false);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    navigate('/manage-contacts');
  };

  return (
    <CssVarsProvider>
      <Sheet
        sx={{
          maxWidth: '1200px',
          margin: 'auto',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
        <IconButton
          variant="plain"
          color="neutral"
          sx={{ width: 'auto', alignSelf: 'start' }}
          onClick={() => window.history.back()}
          aria-label="Go back to Manage Contacts">
          <ArrowBack />
        </IconButton>
        <Typography level="h4">Upload Contacts</Typography>
        {!fileName && fileHeaders.length === 0 && (
          <Sheet
            sx={{
              p: 3,
              backgroundColor: 'background.level2',
              borderRadius: 'md',
              textAlign: 'center'
            }}>
            <Typography level="body-lg" sx={{ mb: 2 }}>
              Instructions:
            </Typography>
            <Typography level="body-sm" sx={{ mb: 1 }}>
              1. Select a valid CSV file with columns to map to the app’s pre-set headers.
            </Typography>
            <Typography level="body-sm" sx={{ mb: 1 }}>
              Note: Only {ROW_LIMIT} rows are allowed to be uploaded.
            </Typography>
            <Button
              component="label"
              variant="outlined"
              color="neutral"
              startDecorator={<CloudUploadIcon />}
              sx={{ mt: 2 }}>
              Select CSV file
              <input
                ref={fileInputRef}
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </Button>
          </Sheet>
        )}

        {fileHeaders.length > 0 && (
          <>
            <Typography level="body-lg">File: {fileName}</Typography>
            <Typography level="body-sm">
              Select respective columns to save data from the file
            </Typography>

            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                {appHeaders.map((appHeader) => (
                  <Grid key={appHeader} xs={12} sm={6} md={4}>
                    <Typography
                      level="body-md"
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      {appHeader}
                      {requiredHeaders.includes(appHeader) && (
                        <Tooltip title="This column is required" arrow>
                          <Warning sx={{ color: 'red', ml: 0.5 }} />
                        </Tooltip>
                      )}
                    </Typography>
                    <FormControl sx={{ mt: 1 }}>
                      <Select
                        value={columnMapping[appHeader]}
                        onChange={(_, newValue) =>
                          handleMappingChange(appHeader, newValue as string)
                        }
                        placeholder="Select column">
                        <Option value="N/A">N/A</Option>
                        {fileHeaders.map((fileHeader) => (
                          <Option key={fileHeader} value={fileHeader}>
                            {fileHeader}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Typography level="h4" sx={{ mt: 4 }}>
              Preview (First 5 Rows):
            </Typography>
            <Box sx={{ overflowX: 'auto', mt: 2 }}>
              {!hasAnyColumnsMapped ? (
                <Typography level="body-sm" sx={{ textAlign: 'center', p: 4 }}>
                  No columns selected
                </Typography>
              ) : (
                <Table
                  variant="soft"
                  borderAxis="both"
                  sx={{
                    tableLayout: 'fixed',
                    minWidth: '800px',
                    '& th, & td': {
                      padding: '0.5rem',
                      border: '1px solid'
                    },
                    '& th': {
                      backgroundColor: 'background.level1'
                    }
                  }}>
                  <thead>
                    <tr>
                      {selectedAppHeaders.map((header) => (
                        <th key={header}>
                          <Typography
                            level="body-sm"
                            sx={{
                              fontWeight: 'bold',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}>
                            {header}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {getPreviewData().map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>
                            <Typography
                              level="body-sm"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}>
                              {cell}
                            </Typography>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Box>
            <Button
              sx={{ width: '200px', mt: 4 }}
              variant="solid"
              color="primary"
              size="md"
              onClick={handleUploadContacts}
              loading={uploadingContacts}
              disabled={!hasRequiredColumnsMapped}>
              Upload Contacts
            </Button>
          </>
        )}
        <Snackbar
          open={snackbarOpen}
          variant="solid"
          color="success"
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          {snackbarMessage}
        </Snackbar>
      </Sheet>
    </CssVarsProvider>
  );
};

export default UploadContactsPage;
