// ProtectedRoutes.tsx
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../store';
import AppLayout from '../pages/app-layout';
import { Box, CircularProgress } from '@mui/joy';
import { setShakeMetadata, setShakeUser } from '../helpers/shake';

const ProtectedRoutes: React.FC = () => {
  const { token, user } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (user) {
      setShakeUser(user);
      setShakeMetadata(user);
    }
  }, [user]);

  if (!token) {
    return <Navigate to="/auth" replace />;
  }

  if (!user) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh' // Full viewport height
        }}>
        <CircularProgress size="lg" />
      </Box>
    );
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default ProtectedRoutes;
