import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Skeleton,
  Typography
} from '@mui/joy';
import React from 'react';
import { IApiContact, IContact } from '../../interfaces/common';

interface FilterPanelProps {
  filters: Record<string, string>;
  inputValues: Record<string, string>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  handleAddFilter: (key: string) => void;
  handleDeleteFilter: (key: string) => void;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  isApplyDisabled: boolean;
  loading: boolean;
  tableFields: (keyof IContact)[] | (keyof IApiContact)[];
  headersMap: { [key in keyof IContact]: string } | { [key in keyof IApiContact]: string };
  filtersApplied: boolean;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  filters,
  inputValues,
  handleInputChange,
  handleAddFilter,
  handleDeleteFilter,
  handleApplyFilters,
  handleResetFilters,
  isApplyDisabled,
  loading,
  tableFields,
  headersMap,
  filtersApplied
}) => {
  return (
    <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'md', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
        <Typography level="h4">Filters</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {filtersApplied && (
            <Button onClick={handleResetFilters} variant="soft" color="neutral" size="sm">
              Reset
            </Button>
          )}
          <Button
            onClick={handleApplyFilters}
            variant="solid"
            color="primary"
            size="sm"
            disabled={isApplyDisabled}>
            Apply
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={400} />
      ) : (
        tableFields.map((field) => (
          <FormControl key={field} sx={{ mb: 2 }}>
            <FormLabel>{headersMap[field as keyof typeof headersMap]}</FormLabel>
            <Input
              type="text"
              name={field}
              value={inputValues[field] || ''}
              onChange={(e) => handleInputChange(e, field)}
              disabled={filters[field]?.length > 0}
              endDecorator={
                <Button
                  variant="soft"
                  onClick={() => handleAddFilter(field)}
                  size="sm"
                  disabled={!inputValues[field]?.trim()}>
                  <Add />
                </Button>
              }
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
              {filters[field] && (
                <Chip
                  size="sm"
                  variant="soft"
                  color="primary"
                  endDecorator={<ChipDelete onDelete={() => handleDeleteFilter(field)} />}
                  sx={{ mr: 0.5, mb: 0.5 }}>
                  {filters[field]}
                </Chip>
              )}
            </Box>
          </FormControl>
        ))
      )}
    </Sheet>
  );
};

export default FilterPanel;
