import { useState, FC, ReactNode } from 'react';
import { Box } from '@mui/joy';
import Navbar from '../components/common/navbar';
import Drawer from '../components/common/drawer';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Drawer open={sidebarOpen} />
      <Box
        sx={{
          flexGrow: 1,
          transition: 'all 0.3s ease',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Navbar toggleDrawer={() => setSidebarOpen((prev) => !prev)} />
        <Box sx={{ padding: '1rem', flexGrow: 1, overflow: 'auto' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
