import {
  CssVarsProvider,
  Sheet,
  Typography,
  Button,
  Box,
  CircularProgress,
  Input,
  FormControl,
  FormLabel,
  FormHelperText
} from '@mui/joy';
import EmailIcon from '@mui/icons-material/Email';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyEmail } from '../../services/api';
import { TVerifyEmailPayload } from '../../interfaces/api';
import { Field, Formik, FormikHelpers, Form } from 'formik';
import {
  TVerifyEmailSchema,
  VerifyEmailInitialValues,
  VerifyEmailSchema
} from '../../constants/yup';
import { handleAxiosError } from '../../utils/api';
import useRedirect from '../../hooks/useRedirect';

export default function EmailConfirmationPage() {
  const email = useLocation().state?.email || '';

  const navigate = useNavigate();

  useRedirect();

  const handleVerifyCode = async (
    values: TVerifyEmailSchema,
    { setSubmitting }: FormikHelpers<TVerifyEmailSchema>
  ) => {
    try {
      setSubmitting(true);
      toast.info('Verifying code...');
      const payload: TVerifyEmailPayload = {
        username: email,
        confirmationCode: values.confirmationCode
      };
      const response = await verifyEmail(payload);
      if (response.status === 200) {
        toast.success('Email verified successfully');
        navigate('/auth');
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('error verifying code', errMsg);
      toast.error(`Error verifying code: ${errMsg}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CssVarsProvider>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <Sheet
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            background: 'white'
          }}>
          <Typography level="h2" component="h1" sx={{ mb: 4 }}>
            EngageSwitch
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
            <EmailIcon sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />

            <Typography sx={{ mb: 0 }}>We've sent a verification code on email:</Typography>

            <Typography level="h4" sx={{ mb: 3 }}>
              {email}
            </Typography>

            <Typography sx={{ mb: 4 }}>
              Please check your inbox and enter the code below to confirm your email address.
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Formik
                initialValues={VerifyEmailInitialValues}
                validationSchema={VerifyEmailSchema}
                onSubmit={handleVerifyCode}>
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <FormControl sx={{ my: 1 }}>
                      <FormLabel>Verification Code</FormLabel>
                      <Field
                        as={Input}
                        name="confirmationCode"
                        placeholder="Enter code"
                        error={touched.confirmationCode && errors.confirmationCode}
                      />
                      {touched.confirmationCode && errors.confirmationCode && (
                        <FormHelperText>{errors.confirmationCode}</FormHelperText>
                      )}
                    </FormControl>

                    <Button
                      type="submit"
                      variant="solid"
                      color="primary"
                      size="lg"
                      sx={{ width: '100%', mt: 1 }}
                      disabled={isSubmitting}>
                      {isSubmitting ? <CircularProgress size="sm" /> : 'Verify'}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Sheet>

        <Sheet
          sx={{
            width: '50%',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            p: 4
          }}>
          <Typography level="h2" sx={{ mb: 2 }}>
            Welcome aboard!
          </Typography>
          <Typography level="body-md" sx={{ mb: 4, textAlign: 'center' }}>
            You're one step away from accessing our powerful tools for salespeople, recruiters, and
            marketers.
          </Typography>
          <Button variant="solid" color="primary" size="lg">
            Learn more about EngageSwitch.com
          </Button>
        </Sheet>
      </Box>
    </CssVarsProvider>
  );
}
