import {
  ExpandMore as ExpandMoreIcon,
  List as ListIcon,
  Refresh as RefreshIcon,
  UploadFile as UploadFileIcon
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  CssVarsProvider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Skeleton,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography
} from '@mui/joy';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddStepModal from '../components/contacts/addStepModal';
import OverviewTab from '../components/sequences/overviewTab';
import SequenceContacts from '../components/sequences/sequenceContacts';
import { ISequence } from '../interfaces/common';
import { getSequences, updateSequenceName } from '../services/api';
import { useAppDispatch, useAppSelector } from '../store';
import { setLoadingSequences, updateSequences, updateSequenceStore } from '../store/app';
import { handleAxiosError } from '../utils/api';

const SequenceDetailPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sequenceId = location?.state?.seq;

  // Receiving the selected sequence via state
  const sequences = useAppSelector((state) => state.app.sequences);
  const sequence = sequences.find((seq) => seq.id === sequenceId);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(sequence?.status === 'active');
  const [isAddStepModalOpen, setIsAddStepModalOpen] = useState<boolean>(false);
  const [sequenceName, setSequenceName] = useState<string | undefined>(sequence?.name);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const fetchSequences = async () => {
    setIsRefreshing(true);
    try {
      dispatch(setLoadingSequences(true));
      const response = await getSequences();
      const sequences: ISequence[] = response.data.sequences;

      // Update the sequences in the Redux store
      dispatch(updateSequences(sequences));

      toast.success('Sequences refreshed successfully.');
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.error('Error refreshing sequences:', errMsg);
      toast.error(`Error refreshing sequences: ${errMsg}`);
    } finally {
      setIsRefreshing(false);
      dispatch(setLoadingSequences(false));
    }
  };

  if (!sequence) {
    navigate('/sequences');
    return null;
  }

  const handleGoToUploadContacts = () => {
    navigate('/upload-contacts', { state: { sequenceId: sequence.id } });
  };

  const handleGoToPeoples = () => {
    navigate('/manage-contacts');
  };

  // const handleEditStep = (stepNumber: number) => {
  //   alert(`Editing Step ${stepNumber}`);
  // };

  const handleDeleteStep = (stepNumber: number) => {
    alert(`Deleting Step ${stepNumber}`);
  };

  const handleUpdateSequenceName = async () => {
    setIsLoading(true);
    try {
      const payload = {
        sequence_id: sequence.id,
        name: sequenceName
      };

      const response = await updateSequenceName(payload);

      if (response.status === 200) {
        dispatch(updateSequenceStore(payload));
        toast.success(response.data.message);
        setIsLoading(false);
        fetchSequences();
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.error('Error updating sequence name', errMsg);
      toast.error(`Error updating sequence name: ${errMsg}`);
      setIsLoading(false);
    }
  };

  return (
    <CssVarsProvider>
      <Box sx={{ padding: 4 }}>
        {/* Breadcrumbs */}
        <Breadcrumbs sx={{ mb: 2 }}>
          <Link onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            Home
          </Link>
          <Link onClick={() => navigate('/manage-sequence')} sx={{ cursor: 'pointer' }}>
            Sequences
          </Link>
          <Typography>{sequence.name}</Typography>
        </Breadcrumbs>

        {/* Header with Actions */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
            gap: 2
          }}>
          <Typography level="h2">{sequence.name}</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              size="sm"
              color="primary"
              variant="solid"
              aria-label="Refresh Sequence"
              onClick={fetchSequences}
              loading={isRefreshing}>
              <RefreshIcon />
            </IconButton>
            {/* Add Contacts Menu */}
            <Dropdown>
              <MenuButton variant="solid" color="primary" endDecorator={<ExpandMoreIcon />}>
                Add Contacts
              </MenuButton>
              <Menu placement="bottom-start">
                <MenuItem onClick={handleGoToUploadContacts}>
                  <UploadFileIcon fontSize="small" sx={{ mr: 1 }} />
                  Upload CSV
                </MenuItem>
                <MenuItem onClick={handleGoToPeoples}>
                  <ListIcon fontSize="small" sx={{ mr: 1 }} />
                  Select List
                </MenuItem>
              </Menu>
            </Dropdown>

            {/* Add Step Button */}
            <Button variant="solid" color="primary" onClick={() => setIsAddStepModalOpen(true)}>
              Add Step
            </Button>

            {/* Active Switch */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography level="body-md">Active</Typography>
              <Switch
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                color={isActive ? 'primary' : 'neutral'}
              />
            </Box>
          </Box>
        </Box>
        {isRefreshing ? (
          // Render Skeleton Loader
          <Box>
            {/* Skeleton for Tabs */}
            <Skeleton variant="rectangular" height={40} sx={{ mb: 3 }} />

            {/* Skeleton for Tab Content */}
            <Skeleton variant="rectangular" height={400} />
          </Box>
        ) : (
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(Number(newValue))}
            sx={{ mb: 3 }}>
            <TabList variant="plain" sx={{ gap: 2, overflowX: 'auto' }}>
              <Tab color={selectedTab === 0 ? 'primary' : 'neutral'}>Overview</Tab>
              <Tab color={selectedTab === 1 ? 'primary' : 'neutral'}>Contacts</Tab>
              <Tab color={selectedTab === 2 ? 'primary' : 'neutral'}>Settings</Tab>
            </TabList>

            {/* Overview Tab */}
            <TabPanel value={0}>
              <OverviewTab sequence={sequence} handleDelete={handleDeleteStep} />
            </TabPanel>

            <TabPanel value={1}>
              <SequenceContacts sequenceId={sequence.id} />
            </TabPanel>

            <TabPanel value={2}>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  maxWidth: 400,
                  mt: 2
                }}>
                <FormControl>
                  <FormLabel>Sequence Name</FormLabel>
                  <Input
                    defaultValue={sequence.name}
                    value={sequenceName}
                    onChange={(e) => setSequenceName(e.target.value)}
                    placeholder="Enter new sequence name"
                    sx={{ mt: 1 }}
                  />
                </FormControl>

                <Button
                  onClick={handleUpdateSequenceName}
                  loading={isLoading}
                  variant="solid"
                  color="primary">
                  Update Name
                </Button>
              </Box>
            </TabPanel>
          </Tabs>
        )}

        <AddStepModal
          update={fetchSequences}
          open={isAddStepModalOpen}
          sequenceSteps={sequence.steps.length}
          onClose={() => setIsAddStepModalOpen(false)}
          stepNumber={sequence.steps.length + 1}
          sequenceId={sequence.id}
        />
      </Box>
    </CssVarsProvider>
  );
};

export default SequenceDetailPage;
