import { Box, Card, CardContent, CssVarsProvider, Typography } from '@mui/joy';
import { Mail as MailIcon } from '@mui/icons-material';
import { IMetrics } from '../interfaces/common';
import { useEffect, useState } from 'react';
import { getDashboardMetrics } from '../services/api';
import { handleAxiosError } from '../utils/api';
import { toast } from 'react-toastify';
import MetricCard from '../components/dashboard/metric-card';

export default function DashboardPage() {
  const [metrics, setMetrics] = useState<IMetrics | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getMetrics = async () => {
      try {
        setLoading(true);
        const res = await getDashboardMetrics();
        setMetrics(res.data.aggregatedMetrics);
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(errMsg);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getMetrics();
  }, []);

  return (
    <CssVarsProvider>
      <Box sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 2 }}>
        <Card variant="soft" color="primary">
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'center' }}>
              <MailIcon
                sx={{
                  mr: 1,
                  padding: '0.5rem',
                  borderRadius: '50%',
                  backgroundColor: 'background.level2'
                }}
              />
              <Typography color="primary" level="body-lg" sx={{ fontWeight: 'bold' }}>
                {metrics?.delivered} Emails Delivered
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                padding: '0.5rem',
                gap: 3
              }}>
              <MetricCard metric={'Open'} value={metrics?.opens} loading={loading} />
              <MetricCard metric={'Clicks'} value={metrics?.clicks} loading={loading} />
              <MetricCard metric={'Blocks'} value={metrics?.blocks} loading={loading} />
              <MetricCard
                metric={'Invalid Emails'}
                value={metrics?.invalid_emails}
                loading={loading}
              />
              <MetricCard metric={'Spam Reports'} value={metrics?.spam_reports} loading={loading} />
              <MetricCard metric={'Unsubscribes'} value={metrics?.unsubscribes} loading={loading} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </CssVarsProvider>
  );
}
