import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  Option,
  Select,
  Sheet,
  Skeleton,
  Snackbar,
  Table,
  Typography
} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IApiContact } from '../../interfaces/common';
import { getSequenceContacts } from '../../services/api';
import { handleAxiosError } from '../../utils/api';
import dayjs from 'dayjs';

interface ISequenceContacts {
  sequenceId: number;
}

type SortConfig = {
  key: keyof IApiContact;
  direction: 'asc' | 'desc';
} | null;

const headersMap: { [key in keyof IApiContact]: string } = {
  ContactId: 'Contact ID',
  FirstName: 'First Name',
  LastName: 'Last Name',
  JobTitle: 'Job Title',
  CompanyName: 'Company Name',
  Email: 'Email',
  Phone: 'Phone',
  Industry: 'Industry',
  LinkedIn: 'LinkedIn',
  CompanySize: 'Company Size',
  UserId: 'User ID',
  CompanyId: 'Company ID',
  Status: 'Status',
  CurrentStepId: 'Current Step',
  LastEvent: 'Last Event',
  LastUpdated: 'Last Updated',
  SequenceId: 'Sequence ID',
  FileName: 'File Name'
};

const tableFields: (keyof IApiContact)[] = [
  'FirstName',
  'LastName',
  'Email',
  'JobTitle',
  'CompanyName',
  'Phone',
  'Industry',
  'Status',
  'CurrentStepId',
  'LastEvent',
  'LastUpdated'
];

const fieldsToCapitalize: (keyof IApiContact)[] = [
  'FirstName',
  'LastName',
  'CompanyName',
  'JobTitle',
  'Industry'
];

const SequenceContacts: React.FC<ISequenceContacts> = ({ sequenceId }) => {
  const [contacts, setContacts] = useState<IApiContact[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortConfig, setSortConfig] = useState<SortConfig>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(0);
  const [selectedContacts, setSelectedContacts] = useState<IApiContact[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);

  const nextKey = useRef<string | undefined>(undefined);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const visibleTableFields = isSmallScreen
    ? (['FirstName', 'LastName', 'Email', 'Status'] as (keyof IApiContact)[])
    : tableFields;

  useEffect(() => {
    const fetchContacts = async () => {
      if (!hasMore) return;

      setLoading(true);
      try {
        const response = await getSequenceContacts({
          id: sequenceId,
          nextKey: nextKey.current,
          pageSize: rowsPerPage
        });
        const data = response.data;
        if (nextKey.current) {
          setContacts((prev) => [...prev, ...data.contacts]);
        } else {
          setContacts(data.contacts);
        }
        if (data.nextKey) {
          nextKey.current = data.nextKey;
        } else {
          setHasMore(false);
        }
      } catch (err) {
        const errMsg = handleAxiosError(err);
        console.error('Error fetching contacts:', errMsg);
        toast.error('Failed to load contacts.');
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceId, toggleRefresh]);

  const handleRefreshContacts = async () => {
    setRowsPerPage(100);
    setPage(0);
    setHasMore(true);
    nextKey.current = undefined;
    setToggleRefresh((prev) => !prev);
  };

  const handleSort = (key: keyof IApiContact) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedContacts = [...contacts].sort((a, b) => {
      const aValue = a[key] || '';
      const bValue = b[key] || '';
      return direction === 'asc'
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setSortConfig({ key, direction });
    setContacts(sortedContacts);
  };

  const paginatedContacts = useMemo(() => {
    return contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [contacts, page, rowsPerPage]);

  const handleSelectContact = (contact: IApiContact) => {
    setSelectedContacts((prevSelected) => {
      const isSelected = prevSelected.some((c) => c.ContactId === contact.ContactId);
      if (isSelected) {
        return prevSelected.filter((c) => c.ContactId !== contact.ContactId);
      } else {
        return [...prevSelected, contact];
      }
    });
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedContacts(paginatedContacts);
    } else {
      setSelectedContacts([]);
    }
  };

  // const handleDeleteSelected = () => {
  //   const remainingContacts = contacts.filter(
  //     (contact) => !selectedContacts.some((c) => c.ContactId === contact.ContactId)
  //   );
  //   setContacts(remainingContacts);
  //   setFilteredContacts(remainingContacts);
  //   setSelectedContacts([]);
  //   toast.success('Selected contacts deleted.');
  // };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setContacts([]);
    setToggleRefresh((prev) => !prev);
  };

  const handleChangePage = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setPage((prev) => prev - 1);
    } else {
      const contactsToShow = page * rowsPerPage + rowsPerPage;
      if (contactsToShow < contacts.length) {
        setPage((prev) => prev + 1);
      } else if (contactsToShow >= contacts.length && hasMore) {
        setPage((prev) => prev + 1);
        setToggleRefresh((prev) => !prev);
      } else {
        toast.info('No more contacts to fetch');
      }
    }
  };

  const capitalizeWords = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getDisplayValue = (field: keyof IApiContact, value: string | number | null) => {
    if (
      value === null ||
      value === undefined ||
      (typeof value === 'string' && value.trim() === '')
    ) {
      return 'N/A';
    }
    if (field === 'LastUpdated') {
      return dayjs(value).format('MM/DD/YYYY');
    }
    if (fieldsToCapitalize.includes(field)) {
      return capitalizeWords(value.toString());
    }
    return value;
  };

  return (
    <Box>
      <Snackbar
        open={loading}
        variant="solid"
        color="primary"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        Loading contacts...
      </Snackbar>

      <Sheet
        variant="outlined"
        sx={{
          p: 2,
          borderRadius: 'md',
          minHeight: 500,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            flexWrap: 'wrap',
            gap: 1
          }}>
          {/* Title */}
          <Typography level="h4">Sequence Contacts</Typography>

          {/* Actions */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              size="sm"
              color="primary"
              variant="solid"
              onClick={handleRefreshContacts}
              aria-label="Refresh Contacts">
              <RefreshIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Counts */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            gap: 1,
            flexWrap: 'wrap'
          }}>
          <Chip
            size="sm"
            color="primary"
            variant="soft"
            startDecorator={<Typography>Total:</Typography>}>
            {contacts.length}
          </Chip>
          {selectedContacts.length > 0 && (
            <Chip
              size="sm"
              color="warning"
              variant="soft"
              startDecorator={<Typography>Selected:</Typography>}>
              {selectedContacts.length}
            </Chip>
          )}
        </Box>

        {loading ? (
          <Skeleton variant="rectangular" height={400} />
        ) : (
          <Box sx={{ flex: 1, overflowX: 'auto' }}>
            <Table
              hoverRow
              variant="outlined"
              borderAxis="bothBetween"
              stickyHeader
              sx={{
                minWidth: '800px',
                tableLayout: 'auto',
                '& thead th': {
                  backgroundColor: 'background.level1',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1
                },
                '& th, & td': {
                  padding: '0.5rem',
                  height: '10px'
                },
                '& tbody tr:nth-of-type(odd)': {
                  backgroundColor: 'background.level1'
                },
                '& tbody tr:hover': {
                  backgroundColor: 'background.level2'
                }
              }}>
              <thead>
                <tr>
                  <th style={{ width: '50px' }}>
                    <Checkbox
                      indeterminate={
                        selectedContacts.length > 0 &&
                        selectedContacts.length < paginatedContacts.length
                      }
                      checked={
                        paginatedContacts.length > 0 &&
                        selectedContacts.length === paginatedContacts.length
                      }
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  </th>
                  {visibleTableFields.map((field) => (
                    <th key={field} onClick={() => handleSort(field)} style={{ cursor: 'pointer' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5
                        }}>
                        {headersMap[field]}
                        {sortConfig?.key === field ? (
                          sortConfig.direction === 'asc' ? (
                            <ArrowUpward fontSize="small" />
                          ) : (
                            <ArrowDownward fontSize="small" />
                          )
                        ) : null}
                      </Box>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedContacts.length === 0 ? (
                  <tr>
                    <td colSpan={visibleTableFields.length + 2}>
                      <Typography level="body-md" sx={{ textAlign: 'center', p: 2 }}>
                        No contacts found
                      </Typography>
                    </td>
                  </tr>
                ) : (
                  paginatedContacts.map((contact) => (
                    <tr
                      key={contact.ContactId}
                      style={{
                        backgroundColor: selectedContacts.some(
                          (c) => c.ContactId === contact.ContactId
                        )
                          ? 'rgba(0, 0, 0, 0.05)'
                          : 'inherit'
                      }}>
                      <td>
                        <Checkbox
                          checked={selectedContacts.some((c) => c.ContactId === contact.ContactId)}
                          onChange={() => handleSelectContact(contact)}
                        />
                      </td>
                      {visibleTableFields.map((field) => (
                        <td
                          key={field}
                          style={{
                            ...(field === 'JobTitle' && { minWidth: '150px' })
                          }}>
                          {getDisplayValue(field, contact[field])}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
            flexWrap: 'wrap',
            gap: 1
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography level="body-sm" sx={{ mr: 1 }}>
              Rows per page:
            </Typography>
            <FormControl size="sm">
              <Select
                onChange={(_, newValue) => handleChangeRowsPerPage(Number(newValue))}
                value={rowsPerPage}
                sx={{ minWidth: '80px' }}>
                <Option value={100}>100</Option>
                <Option value={200}>200</Option>
                <Option value={500}>500</Option>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography level="body-sm" sx={{ mr: 2 }}>
              Page {page + 1} of {Math.ceil(contacts.length / rowsPerPage)}
            </Typography>
            <IconButton
              size="sm"
              onClick={() => handleChangePage('prev')}
              disabled={page === 0}
              aria-label="Previous Page">
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton size="sm" onClick={() => handleChangePage('next')} aria-label="Next Page">
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Sheet>
    </Box>
  );
};

export default SequenceContacts;
