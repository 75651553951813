import { Close, Google, Mail, Microsoft, Link } from '@mui/icons-material';
import { Modal, Sheet, Box, Typography, Checkbox, IconButton, Button } from '@mui/joy';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { linkMailbox } from '../../services/api';
import { ExternalLinks } from '../../constants/external-links';
import { handleAxiosError } from '../../utils/api';

interface AddMailboxModalProps {
  open: boolean;
  onClose: () => void;
}

type EmailProvider = 'google' | 'microsoft' | 'other';

export function AddMailboxModal({ open, onClose }: AddMailboxModalProps) {
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [emailProvider, setEmailProvider] = useState<EmailProvider | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLinkGoogleMailbox = async () => {
    try {
      setLoading(true);
      const response = await linkMailbox();
      window.open(response.data.url, '_self');
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('error linking mailbox', errMsg);
      toast.error(`Error linking mailbox: ${errMsg}`);
    } finally {
      setLoading(false);
    }
  };

  const handleLinkMailbox = () => {
    if (!policyAccepted) {
      toast.error('Please accept the terms of service and privacy policy');
      return;
    }

    if (emailProvider === null) {
      toast.error('Please select an email provider');
      return;
    }

    if (emailProvider !== 'google') {
      toast.error('This email provider is not supported yet');
      return;
    }

    handleLinkGoogleMailbox();
  };

  const handleOpenLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Sheet
        sx={{
          position: 'absolute',
          top: '5%',
          left: '5%',
          right: '5%',
          bottom: '5%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          boxShadow: 'lg',
          borderRadius: 'md',
          bgcolor: 'background.level1',
          border: '1px solid',
          borderColor: 'divider'
        }}>
        <Box display="flex" flexDirection="row" flex={1} position={'relative'}>
          <Box position={'absolute'} top={5} right={5}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={2}
            width="25%"
            padding={4}
            sx={{ backgroundColor: '#031733' }}>
            <Typography level="h4" sx={{ color: 'white' }}>
              Welcome to guided mailbox setup
            </Typography>
            <Typography
              level="body-sm"
              padding={2}
              borderRadius={'0.25rem'}
              sx={{ color: 'white', backgroundColor: '#0c2f73' }}>
              While Gmail is the preferred Email Service Provider (ESP) to get the most out of
              Engage Switch's functionality, Engage Switch supports many different email providers
              and custom email servers.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} width="75%" padding={4}>
            <Typography level="h3">Lets link your mailbox</Typography>
            <Typography level="body-sm">
              Link your mailboxes with Engage Switch to gain full functionality of core engagement
              tools, like emails, sequences, conversations, meetings and more.
            </Typography>
            <Box paddingX={4} paddingY={2}>
              <Typography level="body-sm">Choose your email provider</Typography>
              <Box display="flex" flexDirection="row" mt={1} gap={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${emailProvider === 'google' ? 'blue' : 'gray'}`}
                  borderRadius={'0.25rem'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setEmailProvider('google')}
                  padding={4}
                  flex={1}>
                  <Google color="primary" />
                  <Typography mt={1} level="body-md">
                    Google
                  </Typography>
                  <Typography level="body-sm">Gmail/GSuite</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${emailProvider === 'microsoft' ? 'blue' : 'gray'}`}
                  borderRadius={'0.25rem'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setEmailProvider('microsoft')}
                  padding={4}
                  flex={1}>
                  <Microsoft color="primary" />
                  <Typography mt={1} level="body-md">
                    Microsoft
                  </Typography>
                  <Typography level="body-sm">Hotmail, Live, MSN</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${emailProvider === 'other' ? 'blue' : 'gray'}`}
                  borderRadius={'0.25rem'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setEmailProvider('other')}
                  padding={4}
                  flex={1}>
                  <Mail color="primary" />
                  <Typography mt={1} level="body-md">
                    Other
                  </Typography>
                  <Typography level="body-sm">Any Provider, IMAP</Typography>
                </Box>
              </Box>
            </Box>
            <Typography level="body-md">Engage Switch Terms of Services</Typography>
            <Box
              display="flex"
              flexDirection="row"
              padding={2}
              borderRadius={1}
              gap={2}
              sx={{ backgroundColor: '#f7f7f7' }}>
              <Checkbox
                checked={policyAccepted}
                onChange={(e) => setPolicyAccepted(e.target.checked)}
              />
              <Typography level="body-sm">
                I agree to Engage Switch's{' '}
                <span
                  onClick={() => handleOpenLink(ExternalLinks.TERMS_OF_SERVICE)}
                  style={{ color: 'blue', cursor: 'pointer' }}>
                  Terms of Service
                </span>{' '}
                and{' '}
                <span
                  onClick={() => handleOpenLink(ExternalLinks.PRIVACY_POLICY)}
                  style={{ color: 'blue', cursor: 'pointer' }}>
                  Privacy Policy
                </span>{' '}
                and will not use Engage Switch to send any spam, unsolicited or harassing emails
                (commercial or otherwise) in violation of any applicable laws. By clicking "Link
                mailbox" below, I acknowledge that business contact data submitted from my email
                account to Engage Switch may be used to provide and improve Engage Switch's services
                as further described in our Terms of Service. Learn more about data sharing.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignSelf={'flex-end'}
              justifyContent={'flex-end'}>
              <Button
                startDecorator={<Link />}
                loading={loading}
                variant="solid"
                color="primary"
                onClick={handleLinkMailbox}>
                Link mailbox
              </Button>
            </Box>
          </Box>
        </Box>
      </Sheet>
    </Modal>
  );
}
