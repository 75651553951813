import { Box, Button, Modal, Sheet, Typography } from '@mui/joy';
import React from 'react';

interface AddContactsToSequenceModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  contacts: string;
  sequenceName: string;
  loading: boolean;
}

const AddContactsToSequenceModal: React.FC<AddContactsToSequenceModalProps> = ({
  open,
  onClose,
  onSubmit,
  contacts,
  sequenceName,
  loading
}) => {
  const handleCreate = () => {
    onSubmit();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Sheet
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 },
          padding: 4,
          boxShadow: 'lg',
          borderRadius: 'md',
          bgcolor: 'background.level1',
          border: '1px solid',
          borderColor: 'divider'
        }}>
        <Typography level="h4" component="h2">
          Are you sure you want to add {contacts} contacts to {sequenceName}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="plain" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={loading}
            onClick={handleCreate}
            disabled={loading}>
            Confirm
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default AddContactsToSequenceModal;
