import { Card, CardContent, Typography } from '@mui/joy';
import { MarkEmailRead } from '@mui/icons-material';

interface IMetricCardProps {
  metric: string;
  value?: number;
  loading: boolean;
}

export default function MetricCard({ metric, value, loading }: IMetricCardProps) {
  return (
    <Card variant="outlined" color="neutral" sx={{ textAlign: 'center' }}>
      <CardContent>
        <MarkEmailRead color="primary" sx={{ alignSelf: 'center' }} />
        <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>
          {metric}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', color: 'primary', fontSize: '24px' }}>
          {loading ? '...' : value ?? 'N/A'}
        </Typography>
      </CardContent>
    </Card>
  );
}
