import { Box, Button, Divider, Input, Modal, Sheet, Typography } from '@mui/joy';
import React, { useState } from 'react';

interface CreateSequenceModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (sequenceName: string) => void;
  isLoading: boolean;
}

const CreateSequenceModal: React.FC<CreateSequenceModalProps> = ({
  open,
  onClose,
  onSubmit,
  isLoading
}) => {
  const [sequenceName, setSequenceName] = useState<string>('');
  const [error, setError] = useState<string>('');

  // Simple validation check
  const validateSequenceName = () => {
    if (sequenceName.length < 3) {
      setError('Sequence name must be at least 3 characters long');
      return false;
    } else if (sequenceName.length > 50) {
      setError('Sequence name must be at most 50 characters long');
      return false;
    }
    setError('');
    return true;
  };

  // Handler for create button
  const handleCreate = () => {
    if (validateSequenceName()) {
      onSubmit(sequenceName.trim());
    }
  };

  // Dummy hours for days
  const dummySchedule = [
    { day: 'Monday', hours: '9:00 AM - 5:00 PM' },
    { day: 'Tuesday', hours: '9:00 AM - 5:00 PM' },
    { day: 'Wednesday', hours: '9:00 AM - 5:00 PM' },
    { day: 'Thursday', hours: '9:00 AM - 5:00 PM' },
    { day: 'Friday', hours: '9:00 AM - 5:00 PM' },
    { day: 'Saturday', hours: '10:00 AM - 3:00 PM' },
    { day: 'Sunday', hours: 'Closed' }
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Sheet
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 },
          padding: 4,
          boxShadow: 'lg',
          borderRadius: 'md',
          bgcolor: 'background.level1',
          border: '1px solid',
          borderColor: 'divider'
        }}>
        <Typography level="h4" component="h2">
          Create New Sequence
        </Typography>
        <Typography level="body-md" mb={1} color="neutral">
          Enter a unique name for your sequence.
        </Typography>

        <Input
          placeholder="Sequence Name"
          fullWidth
          required
          variant="outlined"
          value={sequenceName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSequenceName(e.target.value)}
          error={Boolean(error)}
          sx={{ mb: 2 }}
        />

        {/* Schedule Section */}
        <Divider sx={{ my: 2 }} />
        <Typography level="h4" component="h3" mb={1}>
          Schedule
        </Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
          {dummySchedule.map((schedule) => (
            <Box key={schedule.day}>
              <Typography level="body-md" fontWeight="bold">
                {schedule.day}
              </Typography>
              <Typography level="body-sm" color="neutral">
                {schedule.hours}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="plain" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="solid"
            color="primary"
            onClick={handleCreate}
            disabled={sequenceName.length === 0}
            loading={isLoading}>
            Create
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default CreateSequenceModal;
