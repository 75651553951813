import { AxiosError } from 'axios';
import { TErrorResponse } from '../interfaces/api';
import * as Sentry from '@sentry/react';
import { logoutUser } from './general';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleAxiosError = (error: any): string => {
  let errorMessage: string;
  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError<TErrorResponse>;
    if (axiosError.response?.status === 401) {
      logoutUser();
      return 'Unauthorized';
    }
    if (axiosError.request) {
      errorMessage = JSON.parse(axiosError.request.response)?.error;
    } else if (axiosError.response) {
      errorMessage = JSON.parse(axiosError.response.data)?.error;
    } else {
      errorMessage = axiosError.message;
    }
  } else {
    errorMessage = error.message;
  }
  Sentry.captureException(errorMessage);
  return errorMessage;
};
