import { Field, Formik, FormikHelpers, Form } from 'formik';
import { useState } from 'react';
import { LoginInitialValues, LoginSchema, TLoginSchema } from '../../constants/yup';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import { toast } from 'react-toastify';
import { TLoginPayload } from '../../interfaces/api';
import { getUserDetails, login } from '../../services/api';
import { handleAxiosError } from '../../utils/api';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { updateToken, updateUser } from '../../store/app';
import { useAppDispatch } from '../../store';
import { AxiosError } from 'axios';
import { setShakeMetadata, setShakeUser } from '../../helpers/shake';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogin = async (
    values: TLoginSchema,
    { setSubmitting }: FormikHelpers<TLoginSchema>
  ) => {
    try {
      setSubmitting(true);
      toast.info('Logging in...');
      const payload: TLoginPayload = {
        email: values.email.trim().toLowerCase(),
        password: values.password.trim()
      };
      const response = await login(payload);

      if (response.status === 200) {
        const token = response.data?.token || '';
        if (!token) {
          throw new Error('No token found');
        }

        dispatch(updateToken(token));

        try {
          toast.info('Fetching user data...');
          const res = await getUserDetails();
          if (res.status === 200) {
            const userData = res.data;
            if (userData) {
              setShakeUser(userData);
              setShakeMetadata(userData);
              dispatch(updateUser(userData));
              toast.success('Logged in successfully');
              navigate('/dashboard');
            } else {
              throw new Error('User data is empty');
            }
          } else {
            throw new Error('Failed to fetch user data');
          }
        } catch (error) {
          throw new Error(`Error fetching user data: ${error}`);
        }
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('Error logging in', errMsg);
      toast.error(`Error logging in: ${errMsg}`);

      if (error instanceof AxiosError && error?.response?.status === 403) {
        navigate('/auth/email-confirmation', {
          state: { email: values.email.trim().toLowerCase() }
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={LoginInitialValues}
      validationSchema={LoginSchema}
      onSubmit={handleLogin}>
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormControl sx={{ my: 1 }}>
            <FormLabel>Email</FormLabel>
            <Field
              as={Input}
              name="email"
              placeholder="Email"
              error={touched.email && errors.email}
            />
            {touched.email && errors.email && <FormHelperText>{errors.email}</FormHelperText>}
          </FormControl>

          <FormControl sx={{ my: 1 }}>
            <FormLabel>Password</FormLabel>
            <Field
              as={Input}
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter your password"
              error={touched.password && errors.password}
              endDecorator={
                showPassword ? (
                  <VisibilityOff
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <Visibility sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(true)} />
                )
              }
            />
            {touched.password && errors.password && (
              <FormHelperText>{errors.password}</FormHelperText>
            )}
          </FormControl>

          <Button type="submit" fullWidth sx={{ my: 2 }} disabled={isSubmitting}>
            Login
          </Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Link to="/auth/forgot-password">Forgot your password?</Link>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
