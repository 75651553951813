import { Fragment } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './store';

function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <Navigation />
          <ToastContainer autoClose={3000} hideProgressBar closeButton position="top-right" />
        </PersistGate>
      </Provider>
    </Fragment>
  );
}

export default App;
