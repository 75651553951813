import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CssVarsProvider,
  Box,
  Typography,
  Sheet,
  FormControl,
  Button,
  IconButton,
  Select,
  Option,
  Table,
  Chip,
  Grid,
  Skeleton,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  Snackbar
} from '@mui/joy';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ArrowUpward,
  ArrowDownward,
  Refresh as RefreshIcon,
  ImportContacts
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { IContact, ISequence } from '../interfaces/common';
import { getContacts, uploadContactsToSequenceV2 } from '../services/api';
import { handleAxiosError } from '../utils/api';
import { useAppDispatch, useAppSelector } from '../store';
import { saveContacts } from '../store/app';
import FilterPanel from '../components/contacts/filterPanel';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import AddContactsToSequenceModal from '../components/contacts/addSelectedContactsToSequence';
import { TUploadContactsToSequenceV2Payload } from '../interfaces/api';

const headersMap: { [key in keyof IContact]: string } = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  jobTitle: 'Job Title',
  industry: 'Industry',
  companyName: 'Company Name',
  companySize: 'Company Size',
  phone: 'Phone',
  linkedin: 'LinkedIn',
  userId: 'User ID',
  companyId: 'Company ID',
  fileName: 'File Name'
};

const tableFields: (keyof IContact)[] = [
  'firstName',
  'lastName',
  'email',
  'jobTitle',
  'industry',
  'companyName',
  'companySize',
  'phone',
  'linkedin'
];

const fieldsToCapitalize: (keyof IContact)[] = [
  'firstName',
  'lastName',
  'companyName',
  'jobTitle',
  'industry'
];

type SortConfig = {
  key: keyof IContact;
  direction: 'asc' | 'desc';
} | null;

const ManageContactsPage: React.FC = () => {
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortConfig, setSortConfig] = useState<SortConfig>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(0);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [confirmAddModalOpen, setConfirmAddModalOpen] = useState<boolean>(false);
  const [selectedSequence, setSelectedSequence] = useState<ISequence | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const nextKey = useRef<string | undefined>(undefined);

  const filtersApplied = Object.keys(filters).length > 0;

  const paginatedContacts = useMemo(() => {
    return contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [contacts, page, rowsPerPage]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { sequences } = useAppSelector((state) => state.app);

  useEffect(() => {
    let mounted = true;

    const doFetch = async () => {
      if (!hasMore) return;

      try {
        setLoading(true);
        const response = await getContacts({
          pageSize: rowsPerPage,
          filters,
          nextKey: nextKey.current
        });

        if (!mounted) return;

        const data = response.data;
        if (nextKey.current) {
          setContacts((prev) => [...prev, ...data.items]);
        } else {
          setContacts(data.items);
        }
        dispatch(saveContacts(data.items));

        if (data.nextKey) {
          nextKey.current = data.nextKey;
        } else {
          setHasMore(false);
        }

        toast.success('Contacts fetched successfully');
      } catch (error) {
        if (!mounted) return;
        const errMsg = handleAxiosError(error);
        console.error(`Failed to fetch ${filtersApplied ? 'filtered' : ''} contacts`, errMsg);
        toast.error(errMsg);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    doFetch();

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleRefreshContacts = async () => {
    setFilters({});
    setInputValues({});
    nextKey.current = undefined;
    setContacts([]);
    setPage(0);
    setRowsPerPage(100);
    setHasMore(true);
    setRefresh((prev) => !prev);
  };

  const handleSort = (key: keyof IContact) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedContacts = [...contacts].sort((a, b) => {
      const aValue = a[key] || '';
      const bValue = b[key] || '';
      return direction === 'asc'
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setSortConfig({ key, direction });
    setContacts(sortedContacts);
  };

  const handleChangeRowsPerPage = (newValue: number) => {
    setRowsPerPage(newValue);
    setPage(0);
    setContacts([]);
    setRefresh((prev) => !prev);
  };

  const handleChangePage = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setPage((prev) => prev - 1);
    } else {
      const contactsToShow = page * rowsPerPage + rowsPerPage;
      if (contactsToShow < contacts.length) {
        setPage((prev) => prev + 1);
      } else if (contactsToShow >= contacts.length && hasMore) {
        setPage((prev) => prev + 1);
        setRefresh((prev) => !prev);
      } else {
        toast.info('No more contacts to fetch');
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setInputValues({
      ...inputValues,
      [key]: e.target.value
    });
  };

  const handleAddFilter = (key: string) => {
    const value = inputValues[key]?.trim();
    if (value) {
      setFilters((prevState) => ({
        ...prevState,
        [key]: value.toLowerCase()
      }));
      setInputValues({ ...inputValues, [key]: '' });
    }
  };

  const handleDeleteFilter = (key: string) => {
    setFilters((prevState) => {
      const newFilters = { ...prevState };
      delete newFilters[key];
      return newFilters;
    });
  };

  const handleApplyFilters = async () => {
    nextKey.current = undefined;
    setContacts([]);
    setPage(0);
    setHasMore(true);
    setRefresh((prev) => !prev);
  };

  const capitalizeWords = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getDisplayValue = useCallback(
    (field: keyof IContact, value: string | number | undefined) => {
      if (
        value === null ||
        value === undefined ||
        (typeof value === 'string' && value.trim() === '')
      ) {
        return 'N/A';
      }
      if (fieldsToCapitalize.includes(field)) {
        return capitalizeWords(value.toString());
      }
      return value;
    },
    []
  );

  const handleConfirmCloseModal = () => setConfirmAddModalOpen(false);

  const handleSequenceClick = (sequence: ISequence) => {
    setSelectedSequence(sequence);
    setConfirmAddModalOpen(true);
  };

  const handleAddContactsToSequence = async () => {
    if (!selectedSequence) {
      toast.error('No sequence selected');
      return;
    }

    try {
      setIsUploading(true);
      const payload: TUploadContactsToSequenceV2Payload = {
        filters: Object.keys(filters).length > 0 ? filters : undefined
      };
      const response = await uploadContactsToSequenceV2(selectedSequence.id, payload);
      if (response.status === 201) {
        const numberOfContactsAdded = response.data.totalContactsAdded;
        setSnackbarMessage(`${numberOfContactsAdded} contact(s) uploaded successfully`);
        setConfirmAddModalOpen(false);
        setSnackbarOpen(true);
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.error('Failed to upload contacts to sequence', errMsg);
      toast.error(errMsg);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <CssVarsProvider>
      <AddContactsToSequenceModal
        open={confirmAddModalOpen}
        onClose={handleConfirmCloseModal}
        onSubmit={handleAddContactsToSequence}
        contacts={filtersApplied ? 'filtered' : 'all'}
        sequenceName={selectedSequence?.name || ''}
        loading={isUploading}
      />
      <Snackbar
        open={snackbarOpen}
        variant="solid"
        color="success"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        {snackbarMessage}
      </Snackbar>

      <Box sx={{ p: 2 }}>
        <Typography level="h4" sx={{ mb: 2 }}>
          Manage Contacts
        </Typography>

        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <FilterPanel
              filters={filters}
              inputValues={inputValues}
              handleInputChange={handleInputChange}
              handleAddFilter={handleAddFilter}
              handleDeleteFilter={handleDeleteFilter}
              handleApplyFilters={handleApplyFilters}
              handleResetFilters={handleRefreshContacts}
              isApplyDisabled={!filtersApplied}
              loading={loading}
              tableFields={tableFields}
              headersMap={headersMap}
              filtersApplied={filtersApplied}
            />
          </Grid>

          <Grid xs={12} md={9}>
            <Sheet
              variant="outlined"
              sx={{
                p: 2,
                borderRadius: 'md',
                minHeight: 500,
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2
                }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    onClick={() => navigate('/upload-contacts')}
                    variant="solid"
                    size="sm"
                    startDecorator={<ImportContacts />}>
                    Import Contacts
                  </Button>
                  <IconButton
                    size="sm"
                    color="primary"
                    variant="solid"
                    onClick={handleRefreshContacts}
                    aria-label="Refresh Contacts">
                    <RefreshIcon />
                  </IconButton>
                </Box>

                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Dropdown>
                    <MenuButton variant="solid" color="primary" endDecorator={<ExpandMoreIcon />}>
                      Sequences
                    </MenuButton>
                    <Menu placement="bottom-start">
                      {sequences.map((sequence, index) => (
                        <MenuItem onClick={() => handleSequenceClick(sequence)} key={index}>
                          {sequence.name}
                        </MenuItem>
                      ))}
                      <MenuItem onClick={() => navigate('/manage-sequence')}>
                        <IconButton variant="solid" color="primary" sx={{ width: '100%' }}>
                          Create
                        </IconButton>
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                  gap: 1
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip
                    size="sm"
                    color="primary"
                    variant="soft"
                    startDecorator={<Typography>Total:</Typography>}>
                    {contacts.length}
                  </Chip>
                </Box>
              </Box>

              {loading ? (
                <Skeleton variant="rectangular" height={400} />
              ) : (
                <Box sx={{ flex: 1, overflow: 'auto' }}>
                  <Table
                    hoverRow
                    variant="outlined"
                    borderAxis="bothBetween"
                    stickyHeader
                    sx={{
                      minWidth: '1000px',
                      tableLayout: 'auto',
                      '& thead th': {
                        backgroundColor: 'background.level1',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                      },
                      '& th, & td': {
                        padding: '0.5rem',
                        height: '10px'
                      },
                      '& tbody tr:nth-of-type(odd)': {
                        backgroundColor: 'background.level1'
                      },
                      '& tbody tr:hover': {
                        backgroundColor: 'background.level2'
                      }
                    }}>
                    <thead>
                      <tr>
                        {/* <th style={{ width: '50px' }}>
                          <Checkbox
                            indeterminate={
                              selectedContacts.length > 0 &&
                              selectedContacts.length < paginatedContacts.length
                            }
                            checked={
                              paginatedContacts.length > 0 &&
                              selectedContacts.length === paginatedContacts.length
                            }
                            onChange={(e) => handleSelectAll(e.target.checked)}
                          />
                        </th> */}
                        {tableFields.map((field) => (
                          <th
                            key={field}
                            onClick={() => handleSort(field)}
                            style={{ cursor: 'pointer' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5
                              }}>
                              {headersMap[field]}
                              {sortConfig?.key === field ? (
                                sortConfig.direction === 'asc' ? (
                                  <ArrowUpward fontSize="small" />
                                ) : (
                                  <ArrowDownward fontSize="small" />
                                )
                              ) : null}
                            </Box>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedContacts.length === 0 ? (
                        <tr>
                          <td colSpan={tableFields.length + 1}>
                            <Typography level="body-md" sx={{ textAlign: 'center', p: 2 }}>
                              No contacts found
                            </Typography>
                          </td>
                        </tr>
                      ) : (
                        paginatedContacts.map((contact) => (
                          <tr key={contact.email} style={{ backgroundColor: 'inherit' }}>
                            {tableFields.map((field) => (
                              <td key={field}>{getDisplayValue(field, contact[field] ?? '')}</td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Box>
              )}

              {/* Pagination Controls */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 2
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography level="body-sm" sx={{ mr: 1 }}>
                    Rows per page:
                  </Typography>
                  <FormControl size="sm">
                    <Select
                      onChange={(_, newValue) => handleChangeRowsPerPage(newValue as number)}
                      value={rowsPerPage}
                      sx={{ minWidth: '80px' }}>
                      <Option value={100}>100</Option>
                      <Option value={200}>200</Option>
                      <Option value={500}>500</Option>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography level="body-sm" sx={{ mr: 2 }}>
                    Page {page + 1} of {Math.ceil(contacts.length / rowsPerPage)}
                  </Typography>
                  <IconButton
                    size="sm"
                    onClick={() => handleChangePage('prev')}
                    disabled={page === 0}
                    aria-label="Previous Page">
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton
                    size="sm"
                    onClick={() => handleChangePage('next')}
                    aria-label="Next Page">
                    <KeyboardArrowRight />
                  </IconButton>
                </Box>
              </Box>
            </Sheet>
          </Grid>
        </Grid>
      </Box>
    </CssVarsProvider>
  );
};

export default ManageContactsPage;
