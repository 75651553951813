import * as Yup from 'yup';

const emailValidation = Yup.string()
  .email('Invalid email')
  .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email cannot contain uppercase letters')
  .required('Required');

export const LoginSchema = Yup.object().shape({
  email: emailValidation,
  password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters')
});
export type TLoginSchema = Yup.InferType<typeof LoginSchema>;
export const LoginInitialValues: TLoginSchema = {
  email: '',
  password: ''
};

export const SenderEmailSchema = Yup.object().shape({
  senderEmail1: emailValidation,
  senderEmail2: emailValidation
});
export type TSenderEmailSchema = Yup.InferType<typeof SenderEmailSchema>;
export const SenderEmailInitialValues: TSenderEmailSchema = {
  senderEmail1: '',
  senderEmail2: ''
};

export const ValidateSendGridSchema = Yup.object().shape({
  apiKey: Yup.string().required('Required')
});
export type TValidateSendGridSchema = Yup.InferType<typeof ValidateSendGridSchema>;
export const ValidateSendGridInitalValues: TValidateSendGridSchema = {
  apiKey: ''
};

export const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: emailValidation,
  password: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
  companyName: Yup.string().required('Required')
});
export type TSignupSchema = Yup.InferType<typeof SignupSchema>;
export const SignupInitialValues: TSignupSchema = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  companyName: ''
};

export const VerifyEmailSchema = Yup.object().shape({
  confirmationCode: Yup.string()
    .required('Required')
    .length(6, 'Code must be 6 characters')
    .matches(/[0-9]/, 'Code must contain only numbers')
});
export type TVerifyEmailSchema = Yup.InferType<typeof VerifyEmailSchema>;
export const VerifyEmailInitialValues: TVerifyEmailSchema = {
  confirmationCode: ''
};

export const ForgotPasswordSchema = Yup.object().shape({
  email: emailValidation
});
export type TForgotPasswordSchema = Yup.InferType<typeof ForgotPasswordSchema>;
export const ForgotPasswordInitialValues: TForgotPasswordSchema = {
  email: ''
};

export const ResetPasswordSchema = Yup.object().shape({
  email: emailValidation,
  confirmationCode: Yup.string()
    .required('Verification code is required')
    .length(6, 'Code must be 6 characters'),
  newPassword: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
  confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match')
});
export type TResetPasswordSchema = Yup.InferType<typeof ResetPasswordSchema>;
export const ResetPasswordInitialValues: TResetPasswordSchema = {
  email: '',
  confirmationCode: '',
  newPassword: '',
  confirmNewPassword: ''
};
