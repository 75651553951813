import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AuthPage from '../pages/auth';
import EmailConfirmationPage from '../pages/auth/email-confirmation';
import ForgotPasswordPage from '../pages/auth/forgot-password';
import ResetPasswordPage from '../pages/auth/reset-password';
import DashboardPage from '../pages/dashboard';
import ManageContactsPage from '../pages/manage-contacts';
import ManageMailboxesPage from '../pages/manage-mailboxes';
import ManageSequencePage from '../pages/manage-sequence';
import SendgridIntegrationPage from '../pages/sendgrid-integration';
import SequenceDetailPage from '../pages/sequence-details';
import UploadContactsPage from '../pages/upload-contacts';
import ProtectedRoutes from './protected-routes';

export default function Navigation() {
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="upload-contacts" element={<UploadContactsPage />} />
          <Route path="manage-contacts" element={<ManageContactsPage />} />
          <Route path="manage-sequence" element={<ManageSequencePage />} />
          <Route path="manage-sequence/:id" element={<SequenceDetailPage />} />
          <Route path="manage-mailboxes" element={<ManageMailboxesPage />} />
          <Route path="/sendgrid-integration" element={<SendgridIntegrationPage />} />
        </Route>

        <Route path="/auth" element={<Outlet />}>
          <Route index element={<AuthPage />} />
          <Route path="email-confirmation" element={<EmailConfirmationPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}
