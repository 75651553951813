import { useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import {
  CssVarsProvider,
  Sheet,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Divider,
  FormHelperText
} from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import {
  ForgotPasswordInitialValues,
  ForgotPasswordSchema,
  TForgotPasswordSchema
} from '../../constants/yup';
import { forgotPassword } from '../../services/api';
import { toast } from 'react-toastify';
import { handleAxiosError } from '../../utils/api';
import useRedirect from '../../hooks/useRedirect';

function ForgotPasswordPage() {
  const [emailError, setEmailError] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useRedirect();

  const handleForgotPassword = async (
    values: TForgotPasswordSchema,
    { setSubmitting }: FormikHelpers<TForgotPasswordSchema>
  ) => {
    try {
      setSubmitting(true);
      const response = await forgotPassword({ email: values.email.trim().toLowerCase() });
      if (response.status === 200) {
        toast.success('Verification code sent to your email');
        navigate('/auth/reset-password', {
          state: { email: values.email.trim().toLowerCase() }
        });
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('error sending verification code', errMsg);
      setEmailError(errMsg);
      toast.error(`Error sending verification code: ${errMsg}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CssVarsProvider>
      <Box sx={{ display: 'flex', height: '100vh', flexDirection: isMobile ? 'column' : 'row' }}>
        <Sheet
          sx={{
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: isMobile ? 2 : 4,
            background: 'white'
          }}>
          <Typography level="h2" component="h1" sx={{ mb: 4 }}>
            EngageSwitch
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Divider>Forgot your password?</Divider>

            <Formik
              initialValues={ForgotPasswordInitialValues}
              validationSchema={ForgotPasswordSchema}
              onSubmit={handleForgotPassword}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <FormControl sx={{ mt: 2 }}>
                    <FormLabel>Email</FormLabel>
                    <Field
                      as={Input}
                      name="email"
                      placeholder="Email"
                      error={touched.email && errors.email}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText>{errors.email}</FormHelperText>
                    )}
                    {emailError && (
                      <FormHelperText sx={{ color: 'red' }}>{emailError}</FormHelperText>
                    )}
                  </FormControl>

                  <Button type="submit" fullWidth sx={{ mt: 2 }} disabled={isSubmitting}>
                    Send Verification Code
                  </Button>
                </Form>
              )}
            </Formik>
            <Button
              type="button"
              fullWidth
              sx={{ mt: 1 }}
              variant="plain"
              onClick={() => navigate('/auth')}>
              Back to login
            </Button>
          </Box>
        </Sheet>

        <Sheet
          sx={{
            width: isMobile ? '100%' : '50%',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            p: isMobile ? 2 : 4,
            mt: isMobile ? 2 : 0
          }}>
          <Typography level="h2" sx={{ mb: 2 }}>
            Revolutionize Your Marketing Strategy
          </Typography>
          <Typography level="body-md" sx={{ mb: 4, textAlign: 'center' }}>
            Harness the power of automation. EngageSwitch is your key to consistent, high-impact
            email marketing.
          </Typography>
          <Button variant="solid" color="primary" size="lg">
            Try EngageSwitch.com
          </Button>
        </Sheet>
      </Box>
    </CssVarsProvider>
  );
}

export default ForgotPasswordPage;
