import { store } from '../store';
import { resetData, resetToken, resetUser } from '../store/app';
import { unsetShakeUser } from '../helpers/shake';

export const logoutUser = () => {
  store.dispatch(resetData());
  store.dispatch(resetUser());
  store.dispatch(resetToken());
  unsetShakeUser();
  window.location.href = '/';
};
