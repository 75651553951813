import { useState } from 'react';
import { CssVarsProvider, Sheet, Typography, Button, Box } from '@mui/joy';
import LaunchIcon from '@mui/icons-material/Launch';
import LoginForm from '../../components/auth/login-form';
import SignupForm from '../../components/auth/signup-form';
import useRedirect from '../../hooks/useRedirect';

function AuthPage() {
  const [loginMode, setLoginMode] = useState<boolean>(true);

  useRedirect();

  return (
    <CssVarsProvider>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <Sheet
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            background: 'white'
          }}>
          <Typography level="h2" component="h1" sx={{ mb: 2 }}>
            Engage Switch
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography
                level="title-md"
                component="span"
                onClick={() => setLoginMode(true)}
                sx={{
                  flex: 1,
                  cursor: 'pointer',
                  pb: 1,
                  textAlign: 'center',
                  ':hover': { color: 'blue', borderBottom: '2px solid blue' },
                  ...(loginMode && {
                    borderBottom: '2px solid blue',
                    color: 'blue'
                  })
                }}>
                Log In
              </Typography>
              <Typography
                level="title-md"
                endDecorator={
                  <LaunchIcon
                    sx={{ cursor: 'pointer', ':hover': { color: 'blue' } }}
                    onClick={() => setLoginMode(false)}
                  />
                }
                component="span"
                onClick={() => setLoginMode(false)}
                sx={{
                  flex: 1,
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ':hover': { color: 'blue', borderBottom: '2px solid blue' },
                  pb: 1,
                  ...(!loginMode && {
                    borderBottom: '2px solid blue',
                    color: 'blue'
                  })
                }}>
                Sign Up
              </Typography>
            </Box>
            {loginMode ? <LoginForm /> : <SignupForm />}
          </Box>
        </Sheet>

        <Sheet
          sx={{
            width: '50%',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            p: 4
          }}>
          <Typography level="h2" sx={{ mb: 2 }}>
            Revolutionize Your Marketing Strategy
          </Typography>
          <Typography level="body-md" sx={{ mb: 4, textAlign: 'center' }}>
            Harness the power of automation. EngageSwitch is your key to consistent, high-impact
            email marketing.
          </Typography>
          <Button variant="solid" color="primary" size="lg">
            Try EngageSwitch.com
          </Button>
        </Sheet>
      </Box>
    </CssVarsProvider>
  );
}

export default AuthPage;
