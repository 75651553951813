import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  Box,
  Typography,
  Button,
  LinearProgress,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Dropdown,
  MenuButton
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { getMailboxes } from '../services/api';
import { IMailbox } from '../interfaces/common';
import dayjs from 'dayjs';
import { AddMailboxModal } from '../components/mailbox/add-mailbox-modal';
import { toast } from 'react-toastify';
import { handleAxiosError } from '../utils/api';

export default function ManageMailboxesPage() {
  const [mailboxes, setMailboxes] = useState<IMailbox[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [addMailboxModalOpen, setAddMailboxModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const req = async () => {
      try {
        setFetching(true);
        const response = await getMailboxes();
        setMailboxes(response.data.integrations);
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(`Error fetching mailboxes: ${errMsg}`);
        console.warn('error fetching mailboxes', errMsg);
      } finally {
        setFetching(false);
      }
    };
    req();
  }, []);

  const handleAddMailbox = () => {
    setAddMailboxModalOpen(true);
  };

  return (
    <CssVarsProvider>
      <Box sx={{ padding: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography level="h4" component="h1">
            My Mailboxes
          </Typography>
          <Button variant="outlined" onClick={handleAddMailbox} startDecorator={<AddIcon />}>
            Link Mailbox
          </Button>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '50%' }}>
          <Typography sx={{ mr: 1 }}>2/5 linked</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <LinearProgress determinate value={40} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingY: 1,
            fontWeight: 'bold',
            borderBottom: '1px solid',
            borderColor: 'divider',
            marginBottom: 1,
            textAlign: 'center',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '25%' }}>
            <Typography>Mailbox</Typography>
          </Box>
          <Box sx={{ width: '25%' }}>
            <Typography>Limits</Typography>
          </Box>
          <Box sx={{ width: '25%' }}>
            <Typography>Deliverability</Typography>
          </Box>
          <Box sx={{ width: '20%' }}>
            <Typography>Last Synced</Typography>
          </Box>
          <Box sx={{ width: '5%' }} />
        </Box>

        <Sheet
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 2,
            borderRadius: 'sm',
            boxShadow: 'sm',
            bgcolor: 'background.level1',
            maxWidth: '100%'
          }}>
          {fetching ? (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Typography>Loading...</Typography>
            </Box>
          ) : mailboxes.length === 0 ? (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Typography>No mailboxes found</Typography>
            </Box>
          ) : (
            mailboxes.map((mailbox, index) => (
              <React.Fragment key={index}>
                <MailboxCard mailbox={mailbox} />
                {index < mailboxes.length - 1 && <Divider />}
              </React.Fragment>
            ))
          )}
        </Sheet>
      </Box>
      <AddMailboxModal open={addMailboxModalOpen} onClose={() => setAddMailboxModalOpen(false)} />
    </CssVarsProvider>
  );
}

interface MailboxCardProps {
  mailbox: IMailbox;
}

const MailboxCard: React.FC<MailboxCardProps> = ({ mailbox }) => {
  const handleUnlink = () => {
    console.log(`Unlinking mailbox: ${mailbox.user_email}`);
  };

  const time2hrsAgo = dayjs().subtract(2, 'hours').toDate();
  const lastSynced = dayjs(time2hrsAgo).format('DD/MM/YYYY HH:mm');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 1,
        borderRadius: 'sm',
        width: '100%'
      }}>
      <Box sx={{ width: '25%' }}>
        <Typography fontWeight="bold">{mailbox.user_email}</Typography>
        <Typography>email aliases</Typography>
        <Typography>(Sending from SendGrid)</Typography>
      </Box>

      <Box sx={{ width: '25%', textAlign: 'center' }}>
        <Box sx={{ gap: 1, textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Daily:</Typography>
          <Typography>
            {/* {mailbox.dailyLimit} / {mailbox.totalDailyLimit} */}
            54 / 100
          </Typography>
        </Box>
        <Box sx={{ gap: 1, textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Hourly:</Typography>
          {/* <Typography>{mailbox.hourlyLimit}</Typography> */}
          <Typography>10</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: '25%',
          gap: 1,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Typography sx={{ fontWeight: 'bold' }}>Fair:</Typography>
        <Typography>{24}%</Typography>
      </Box>

      <Box sx={{ width: '20%', textAlign: 'center' }}>
        <Typography>{lastSynced}</Typography>
      </Box>

      <Box sx={{ width: '5%', textAlign: 'right' }}>
        <Dropdown>
          <MenuButton sx={{ padding: 0, border: 'none' }}>
            <IconButton variant="soft" size="sm">
              <MoreHorizIcon />
            </IconButton>
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem onClick={handleUnlink}>
              <LinkOffIcon sx={{ mr: 1 }} />
              Unlink Mailbox
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
};
